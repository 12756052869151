<template>
	<div>    

    
   
   <div class = "help-gruppe pt-2 cockpitview">
        <div class="help-gruppe-title hide">
          <h2 class = "title my-auto">
            <span class ="title-icon text-center d-inline-block">
              <i class="far  fa-book NO-c"></i>
            </span>
          </h2>
          <div class = "ml-4 my-auto">
            <h2 class="text-center">Using Flowe</h2>
          </div>
        </div>

        <div v-if="showdetailpage && showdetailpage.body" class="card-body w-100 pl-0 pr-0 pt-0">

          <router-link class="mb-2 btn btn-secondary-light" :to="'/'+orgkey+'/help/'"><i aria-hidden="true" class="fal fa-angle-double-left"></i><span class="pl-2">Back</span></router-link>
          <h3>{{ showdetailpage.title }}</h3>

          <hr>          

          <div v-html="showdetailpage.body" id="helppagecontent" ></div>
        </div>

        <div v-if="!showdetailpage.body">

          <a href="https://flowe.io/support" target="_blank" v-if="1==2">
        <div  class="btn btn-secondary-dark btn-lg w-100 pb-1 pt-2">
          <i aria-hidden="true" class="far fa-question-circle"></i>
          <p>Contact us</p>
        </div></a>

        <div >
          
          <div style="width:50%; margin:auto; text-align: center;" v-if="1==2">
          <h3>How can we help?</h3>

          <div class="d-flex">
          <div class="p-2 flex-grow-1">
          <input class="form-control" type="text" name="" placeholder="Search for answers" v-model="filtersearch" @change="applyfilter">
          </div>
          <div class="p-2"><button class="btn btn-secondary" @click="applyfilter">Search</button></div>
          </div>

          </div>

          <div class="results p-2" style="width:70%; margin:auto;" v-if="1==2">

            <div class="mb-2" v-for="(page, key) in pagescontent" :key="key">
              <h5>Create a new objective</h5>
              <div>There are two ways you can create a new objective, from the objective overview page or directly from a board. The method is the same...</div>
            </div>

          </div>

          
        </div>

        <p class="hide"> Use the links below to access the guides in our Help Center (opens in a new browser tab):</p>

        <div class="panelg row" :class="{'panelsm':windowsize<768}" id="helparticles" >

          <ul class="left col-md-6">
          <template v-for="(page, key) in pagescontent">
            <li v-if="(key%2==0 && twocols) || !twocols" :key="'spacer'+panelupdate+key">&nbsp;</li>
            <li v-if="((key%2==0 && twocols) || !twocols ) && page.contents.length > 0" :key="'left'+panelupdate+key" class="article-header mb-2"><span class="h5">{{ page.title }}</span></li>
            <template v-for="(subpage, key2) in page.contents">
              <template v-if="subpage.pageid != '2419621897'">
            <li v-if="(key%2==0 && twocols) || !twocols" :key="'subleft_'+key+'z'+key2" class="article-item mb-1">


              <a :href="'/'+orgkey+'/help/'+subpage.pageid" target="_blank" >{{subpage.title}}</a></li>
              </template>
            </template>            
          </template>
          </ul>

          <ul class="right col-md-6" v-if="windowsize>767"> 
          <template v-for="(page, key) in pagescontent">
            <li v-if="key%2!=0 && twocols" :key="'spacer_'+panelupdate+key">&nbsp;</li>
            <li v-if="( key%2!=0 && twocols ) && page.contents.length > 0" :key="'right_'+panelupdate+key" class="article-header mb-2"><span class="h5">{{ page.title }}</span></li>
            <template v-for="(subpage, key2) in page.contents">
            <li v-if="key%2!=0 && twocols" :key="'subright_'+key+'z'+key2" class="article-item mb-1"><a :href="'/'+orgkey+'/help/'+subpage.pageid" target="_blank">{{subpage.title}}</a></li>
            </template>            
          </template>
          </ul>
          

      </div>

    </div>


      </div>
      <div class="help-gruppe" style = "border: none" id="aboutpanel">
        <p class="text-center">Copyright Ⓒ Flowe</p>
      </div>

  </div>
 </template>
 <style type="text/css">

  #helppagecontent{
    max-width: 800px;
  }

  #helppagecontent *{
    font-size: 15px !important;
   /* line-height: 18px;*/
  }

  #helppagecontent h1{
    font-size: 18px !important;
    font-weight: 700;
  }
  #helppagecontent h2{
    font-size: 15px !important;
    font-weight: 700;
  }
  #helppagecontent h3{
    font-size: 15px !important;
    font-weight: 700;
  }

  #helppagecontent img{
    width: 90% !important;
    margin: auto;
  }
  
  #helpcentermodalmodal.pagefixedwidth .main-wrapper{
    /* margin: 0 auto 0 auto; */
    /*height: 100%;*/
    /*max-width: 1400px;*/
    position: relative;
    /* border: 1px solid red; */
    width: auto;
    margin-right: 140px;
  }

  .h5{
    font-weight: 700 !important;
  }

  .panelsm{
    margin-left: 10px;
  }

  .helppagetitle{
    text-align: left;
  }

  .article-item a:hover, .article-item a:active, .article-item a:focus{
    text-decoration: underline !important; 
  }

  #helparticles{
    /*-webkit-column-count: 2; 
    -moz-column-count: 2; 
    column-count: 2;   */
  }

  #helparticles li{
    list-style: none;
    line-height: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .panelg{

  }

  #helpcentermodalmodal .groupwrapper{
    /*float: left;
   /* width: 33%;
    max-width: 360px;*/
    /*min-height: 225px;
    margin-bottom: 80px;*/
  }

  #helpcentermodalmodal .groupwrapper li{
    list-style: none;
  }


  #helpcentermodalmodal .groupwrapper li a{
    /*width: 250px;*/
    /*overflow: hidden;*/
   /* position: relative;
    display: inline-block;*/
    text-decoration: none;
   /* text-overflow: ellipsis;*/
  }


  #helpcentermodalmodal{
    padding: 0;
  }

</style>
<script type="text/javascript"></script>
<script>
  import {TopBar} from '@/components/topbar'
  import {bus} from '@/main'

  import Dashboardapi from '@/services/api/dashboard'

  export default{
    name:'helpContent',
    props:{
      pageid:String,
      subpageid:String
    },
    data(){
      return{
        showaboutFlowe:false,
        openguidemodal:false,
        pages:[],
        parentids:[],
        windowsize:0,
        sections:[],
        pagescontent:{},
        showpage:{},
        showdetailpage:{},
        filtersearch:"",
        panelupdate:1,
        initialpage:"",
        filtertext:""
      }

    },
    components:{
      TopBar
    },

    created() {
      window.addEventListener("resize", this.windowOnresize);
    },
    destroyed() {
      window.removeEventListener("resize", this.windowOnresize);
    },

    computed:{

      orgkey(){
        return this.$route.params.orgname ? this.$route.params.orgname : null
      },

      twocols(){
        return this.windowsize > 767 ? true:false
      },
      ismobile(){
        return this.$store.state.screenSize
      },
      showhints(){
        return this.$store.state.showhints
      }
    },

    mounted(){

     // alert('')

      if( this.$route.params.helppage ){
        this.initialpage = this.$route.params.helppage + ""
      }

      let self = this

      $('#preloader').addClass('hide')
      if(this.$route.query.page == 'about'){
        this.showaboutFlowe = true
        var aboutpanel = document.getElementById('aboutpanel');
        aboutpanel.scrollIntoView();
      }
      this.$nextTick(function () {
        document.title ='Help | Flowe'
      })

      Dashboardapi.Loadpages().then( function( response ){
        self.pages = response.data.pages
        self.renderpages( function(){


           if( self.pageid != "" ){


                    setTimeout( function(){

                      //console.log( self.pagescontent.length, "bbaaaaaaaaaa" )

                      for( var i=0; i<self.pagescontent.length;i++ ){

                         let page = self.pagescontent[i] 
                      
                          for( var o=0; o<page.contents.length;o++ ){
                          //for( var o in page.contents ){
                            let subpage = page.contents[o]

                            //console.log( subpage.pageid, self.initialpage, "aaaaaaaaaa" )

                            if( subpage.pageid == self.subpageid || subpage.pageid == self.pageid ){
                              //alert(self.pageid)
                              self.showdetail(page, subpage)
                              break
                            }        

                          }
                      }


                      
                    }, 50)

                  }


        })



       

        
      })

      this.windowOnresize()
    },


    methods:{

      applyfilter(){

        this.renderpages()

      },

      windowOnresize(){

        this.windowsize = window.innerWidth
        this.panelupdate++

      },

      searchcontent(){

        this.renderpages()

      },

      showdetail( page, detailpage ){

        /*if(page && page.pageid){
          this.$router.replace('/'+this.orgkey+'/help/'+page.pageid)
        }else{
          this.$router.replace('/'+this.orgkey+'/help')
        }*/

        

        this.showpage = {}  
        this.showdetailpage = {}

        //console.log( page , detailpage.pageid )
        if( (page && detailpage) || ( page == null && detailpage ) ){
         // alert('go')

          if( page == null  && detailpage ){

            this.showpage = { contents:[] }
            this.showdetailpage = detailpage

            console.log("aa")

          }else{

            this.showpage = page
            this.showdetailpage = detailpage

            console.log("bb")

          }

          
        }

        
        
      },
      showhint(){
        this.$store.commit('SET_SHOWHINTS',!this.showhints)
      },

      renderpages( callback ){

        let parentids = []

        let pagescontent = {}

        // first level

        let excludepages = ['2530476034']

        for( var i in this.pages ){

          let page = this.pages[i]

         // console.log( page.pag , "pagexx")

          if( page.parentid != "null" && parentids.indexOf( page.parentid ) == -1 && excludepages.indexOf( page.pageid ) == -1 ){
            parentids.push( page.parentid )

            pagescontent[ page.parentid ] = { "contents":[] }

          }
        }

        for( var i in this.pages ){

          let page = this.pages[i]

          if( pagescontent[ page.parentid ] && excludepages.indexOf( page.pageid ) == -1 ){
            pagescontent[ page.parentid ].contents.push( { "parentid":page.parentid, "body":page.body , "pageid":page.pageid, "title":page.title, "contentids":[], "contents":[] }  )
          }
          
        }

        // second level

        let root = pagescontent["720863239"].contents
        

        for( var o in this.pages ){

          for( var i in root ){

            let page = this.pages[o]

            if( root[i].pageid == page.parentid ){


                root[i].contentids.push( page.pageid )

                if( this.filtersearch == "" ){
                  root[i].contents.push( page )
                }else{

                  if( page.body.includes( this.filtersearch ) ){
                    root[i].contents.push( page )
                  }
                  

                }

                
            }

          }
          
        }        

        pagescontent = root        

        this.pagescontent = pagescontent

        if( callback ){
          callback()
        }

      }
    },
  }
</script>