import {baseurl} from './index.js'

import axios from 'axios'

axios.defaults.baseURL = baseurl

//axios.defaults.headers.common['flowe-event-id'] = this.$store.getters.logeventId

let lastrequest = null

export default {


	//// Master plan

	reOrderTracks( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Plans/?action=reordertracks', URLparams.toString() )

	},

	savePlanPng( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Plans/?action=savepng', URLparams.toString() )
	},

	Exportplancsv( template, boardid, token ){

		//template = ''

		return axios.get(  '/Rest/Plans?action=csv&template='+template+'&boardid='+boardid+'&t=' + token )

	},

	Exportplan( template, boardid, token ){

		return axios.get(  '/Rest/Plans?action=graph&template='+template+'&boardid='+boardid+'&t=' + token )

	},

	Updatetrack( payload ){


		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Plans/?action=update', URLparams.toString() )

	},

	Newtrack( payload ){


		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Plans/?action=new', URLparams.toString() )

	},

	Track( objectid, token){

		
		//console.log( lastrequest )

		if( lastrequest ){
			lastrequest.cancel();
		}

		//console.log( lastrequest )

		const cancelTokenSource = axios.CancelToken.source();
		lastrequest = cancelTokenSource		 

		return axios.get(  '/Rest/Plans?action=gettrack&objectid='+objectid+'&t=' + token , {
		  cancelToken: cancelTokenSource.token
		})

	},

	Tracks( boardid, token){

		return axios.get(  '/Rest/Plans?action=getall&boardid='+boardid+'&t=' + token )

	},

	Trackgraphimage( boardid, objectiveid, token){

		return axios.get(  '/Rest/Plans?action=graphimage&objectiveid='+objectiveid+'&boardid='+boardid+'&t=' + token )

	},

	Trackcomputed( boardid, objectiveid, token){

		return axios.get(  '/Rest/Plans?action=compute&objectiveid='+objectiveid+'&boardid='+boardid+'&t=' + token )

	},

	Trackdelete(  objectid, token){

		return axios.get(  '/Rest/Plans?action=deletetrack&objectid='+objectid+'&t=' + token )

	},

	Trackplan( boardid, objectid, token){

		return axios.get(  '/Rest/Plans?action=delete&boardid=' +boardid+'&objectid='+objectid+'&t=' + token )

	},

	///////// Risks

	Exportriskcsv( template, boardid, token ){

		//template = ''

		return axios.get(  '/Rest/Risks?action=csv&template='+template+'&boardid='+boardid+'&t=' + token )

	},

	Exportrisk( template, boardid, token ){

		return axios.get(  '/Rest/Risks?action=graph&template='+template+'&boardid='+boardid+'&t=' + token )

	},

	UpdateRisk( payload ){


		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Risks/?action=update', URLparams.toString() )

	},

	NewRisk( payload ){


		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Risks/?action=new', URLparams.toString() )

	},

	Risk( objectid, token){

		
		/*console.log( lastrequest )

		if( lastrequest ){
			lastrequest.cancel();
		}

		//console.log( lastrequest )

		const cancelTokenSource = axios.CancelToken.source();
		lastrequest = cancelTokenSource*/

		 

		return axios.get(  '/Rest/Risks?action=getone&objectid='+objectid+'&t=' + token , {
		  //cancelToken: cancelTokenSource.token
		})

	},

	Risks( boardid, token){

		return axios.get(  '/Rest/Risks?action=getall&boardid='+boardid+'&t=' + token )

	},

	Stakeholdergraphimage( boardid, token ){

		return axios.get(  '/Rest/Stakeholders?action=graph&template=inlineimage&boardid='+boardid+'&t=' + token )

	},

	Masterplangraphimage( boardid, objectiveid, token, forexport, template, size, filetype ){



		let action = 'renderpng'

		if( filetype == 'xls' ){
			action = 'renderxls'
		}

		return axios.get(  '/Rest/Plans?forexport='+forexport+'&exporttype=' +filetype + '&action=' + action + '&objectiveid='+objectiveid+'&boardid='+boardid+'&template=' +template+ '&size=' + size + '&t=' + token )

	},

	Risksgraphimage( boardid, objectiveid, token){

		return axios.get(  '/Rest/Risks?action=graphimage&objectiveid='+objectiveid+'&boardid='+boardid+'&t=' + token )

	},

	Riskscomputed( boardid, objectiveid, token){

		return axios.get(  '/Rest/Risks?action=compute&objectiveid='+objectiveid+'&boardid='+boardid+'&t=' + token )

	},

	Deleterisk( boardid, objectid, token){

		return axios.get(  '/Rest/Risks?action=delete&boardid=' +boardid+'&objectid='+objectid+'&t=' + token )

	},


	///////// Stakeholders


	Exportstakeholder( template, boardid, token ){

		return axios.get(  '/Rest/Stakeholders?action=graph&template='+template+'&boardid='+boardid+'&t=' + token  )

	},

	UpdateStakeholder( payload ){


		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Stakeholders/?action=update', URLparams.toString() )

	},

	NewStakeholder( payload ){


		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Stakeholders/?action=new', URLparams.toString() )

	},

	Stakeholder( objectid, token){

		
		//console.log( lastrequest )

		if( lastrequest ){
			lastrequest.cancel();
		}

		//console.log( lastrequest )

		const cancelTokenSource = axios.CancelToken.source();
		lastrequest = cancelTokenSource

		 

		return axios.get(  '/Rest/Stakeholders?action=getone&objectid='+objectid+'&t=' + token , {
		  cancelToken: cancelTokenSource.token
		})

	},

	Stakeholders( boardid, token){

		return axios.get(  '/Rest/Stakeholders?action=getall&boardid='+boardid+'&t=' + token )

	},

	Deletestakeholder( boardid, objectid, token){

		return axios.get(  '/Rest/Stakeholders?action=delete&boardid=' +boardid+'&objectid='+objectid+'&t=' + token )

	},

	Detailsfromemail( token, email ){
		
		//https://capi.flowe.io/Users/Details?token=Q9mgvEmLRr4sTv3712v8wFYZ22aWP9dxwMjrvsZuM73O7SSFBFWSPMqBf7BhaoGl

		return axios.get(  '/Users/Detailsfromemail?token=' + token + '&email='+ email )
		//return axios.get(  '/My/Dashboard/?t=' + payload.userid  )

	},

	Details( uuid ){
		
		//https://capi.flowe.io/Users/Details?token=Q9mgvEmLRr4sTv3712v8wFYZ22aWP9dxwMjrvsZuM73O7SSFBFWSPMqBf7BhaoGl

		return axios.get(  '/Users/Details?token=' + uuid )
		//return axios.get(  '/My/Dashboard/?t=' + payload.userid  )

	},

	Listallusers(){

		//https://dapp.flowe.io/Tools/Listall/?o=users

		return axios.get(  '/Tools/Listall/?o=users' )
	},

	Profile( userid ){

		//https://dapp.flowe.io/Tools/Listall/?o=users

		return axios.get(  '/Rest/Users/?get=' + userid + '&origin=myprofile' )
	},

	Existsbyemail( email, token ){

		//https://dapp.flowe.io/Tools/Listall/?o=users

		return axios.get(  '/Rest/Usersbyemail/?email=' + email + '&token=' + token )
	},

	

	Updateprofile( payload ){

		/*
		"userid": userid ,
		"field": selection "profilepic",
		"value": string
		*/


		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Updateusers/?action=post', URLparams.toString() )
	},

	Requestchangeofemail( userid, toemail, token ){

		//https://dapp.flowe.io/Rest/Requestemailchange?uid=5db8e8231f6d4941f5b593ff&email=cb.ray.an@gmail.com

		return axios.get(  '/Rest/Requestemailchange?uid=' + userid + '&email=' + toemail + '&t=' + token )

	},

	Verifyuser( payload ){
		/* payload t=token&p=password&c=catchword&mode=password|catchword */

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Verifyuser/?action=post', URLparams.toString() )

	},

	Predeactivate( token ){

		return axios.get(  '/Rest/Predeactivateaccount?t=' + token )

	},

	Deactivate( token, confirmtoken ){

		return axios.get(  '/Rest/Deactivateaccount?t=' + token + "&confirmtoken=" + confirmtoken )

	},

	Changepassword( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Changepasswordtry/', URLparams.toString() )

	},

	Registeruser( email, token, context ){
		return axios.get(  "/Rest/Newuser?email=" + email + "&t=" + token + "&context=" + context )
	},

	Logspeed( resource, value, url ){
		return axios.get(  "/Tools/Profile?resource=" + resource + "&value=" + value + "&url=" + encodeURIComponent(url)  )
	}


}

